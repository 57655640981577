<template>
  <div>
    <we-have-got-your-photo-template
      :doctor-info="doctorInfo"
      :user-info="patientInfo"
      :patient-photo="patientPhoto"
      :language="locale"
      :language-options="languageOptions"
      :error="errorMessage"
      :loading="isLoading"
      @change-language="onChangeLanguage"
      @open-instructions="openPhotoInstructions"
      @confirm-photo="confirmPhoto"
      @retake-photo="retakePhoto"
    />

    <photo-instructions-modal />
    <photo-face-low-quality-modal />
    <photo-multiple-faces-modal />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import WeHaveGotYourPhotoTemplate from '@/modules/questionnaire/pages/we-got-your-photo/WeHaveGotYourPhotoTemplate';
import PhotoInstructionsModal from '@/modules/questionnaire/components/dialogs/PhotoInstructionsModal';
import PhotoFaceLowQualityModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoFaceLowQualityModal';
import PhotoMultipleFacesModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoMultipleFacesModal';

import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';
import { doctorAndPatientInfoMixin } from '@/modules/questionnaire/mixins/doctorAndPatientInfoMixin';

import rootTypes from '@/store/types';
import { types as questTypes } from '@/modules/questionnaire/store/types';
import {
  PATIENT_PHOTO_VALIDATION_ERROR,
  PHOTO_VALIDATION_ERROR_MODALS
} from '@/modules/questionnaire/constants/photoAnalysis';

export default {
  name: 'PageWeHaveGotYourPhoto',
  components: {
    WeHaveGotYourPhotoTemplate,
    PhotoInstructionsModal,
    PhotoFaceLowQualityModal,
    PhotoMultipleFacesModal
  },
  mixins: [changeLanguageMixin, doctorAndPatientInfoMixin],
  data() {
    return {
      errorMessage: '',
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      patientPhoto: state => state.questionnaire.patientPhoto,
      allowPhotoResearch: state => state.questionnaire.allowPhotoResearch
    })
  },
  mounted() {
    if (!this.patientPhoto) {
      this.$router.replace({ name: 'LetsMakeASelfie' });
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      updatePatientPhoto: questTypes.actions.UPDATE_PHOTO,
      initQuestionnaire: questTypes.actions.INIT_QUESTIONNAIRE,
      fetchPatientQuestionnairePhoto: questTypes.actions.FETCH_PATIENT_QUESTIONNAIRE_PHOTO
    }),
    async openPhotoInstructions() {
      await this.$asyncModal.show('photo-instructions-modal');
    },
    async confirmPhoto() {
      this.setLoading(true);

      await this.initQuestionnaire();

      const { data } = await this.updatePatientPhoto({
        photoType: 'face',
        photo: this.patientPhoto,
        allowResearch: this.allowPhotoResearch
      });
      const { noFace, severalFaces } = data;

      if (noFace) {
        this.setLoading(false);

        const modalName = PHOTO_VALIDATION_ERROR_MODALS[PATIENT_PHOTO_VALIDATION_ERROR.NO_FACE];

        await this.$asyncModal.show(modalName);
        this.retakePhoto();

        return;
      }

      if (severalFaces) {
        this.setLoading(false);

        const modalName =
          PHOTO_VALIDATION_ERROR_MODALS[PATIENT_PHOTO_VALIDATION_ERROR.MULTIPLE_FACES];

        await this.$asyncModal.show(modalName);
        this.retakePhoto();

        return;
      }

      await this.fetchPatientQuestionnairePhoto();

      await this.$router.replace({ name: 'FaceScanner' });

      this.setLoading(false);
    },
    retakePhoto() {
      this.$router.replace({ name: 'LetsMakeASelfie' });
    }
  }
};
</script>
