<template>
  <photo-validation-error-modal
    name="photo-face-low-quality-modal"
    :title="$t('photoValidation.title.noFace')"
    :error-message="$t('photoValidation.text.noFace')"
  />
</template>

<script>
import PhotoValidationErrorModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoValidationErrorModal';

export default {
  name: 'PhotoFaceLowQualityModal',
  components: { PhotoValidationErrorModal }
};
</script>
