<template>
  <photo-validation-error-modal
    name="photo-multiple-faces-modal"
    :title="$t('photoValidation.title.multipleFaces')"
    :error-message="$t('photoValidation.text.multipleFaces')"
  />
</template>

<script>
import PhotoValidationErrorModal from '@/modules/questionnaire/components/dialogs/photo-validation/PhotoValidationErrorModal';

export default {
  name: 'PhotoMultipleFacesModal',
  components: { PhotoValidationErrorModal }
};
</script>
