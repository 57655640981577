<template>
  <questionnaire-page-template
    :title="$t('gotYourPhoto.title')"
    :doctor-info="doctorInfo"
    :user-info="userInfo"
    :language-options="languageOptions"
    :language="language"
    no-footer
    no-divider
    content-centered
    basic-background
    @change-language="$emit('change-language', $event)"
  >
    <div class="we-have-got-photo-page-content">
      <i18n
        path="gotYourPhoto.introText"
        tag="p"
        class="we-have-got-photo-page-content__text u-typography-helvetica u-text u-text--s"
      >
        <span
          class="we-have-got-photo-page-content__text--link"
          @click="$emit('open-instructions')"
          >{{ $t('gotYourPhoto.label.photoStandards') }}</span
        >
      </i18n>

      <div class="we-have-got-photo-page-content__main-content main-content">
        <img class="main-content__image" :src="patientPhoto" />
        <div class="main-content__action-buttons-wrapper">
          <div class="action-buttons">
            <u-button class="action-buttons__button" size="small" @click="$emit('confirm-photo')">{{
              $t('gotYourPhoto.action.confirm')
            }}</u-button>
            <u-button
              class="action-buttons__button"
              kind="secondary"
              size="small"
              @click="$emit('retake-photo')"
              >{{ $t('gotYourPhoto.action.tryAgain') }}</u-button
            >
          </div>
        </div>
      </div>
    </div>
  </questionnaire-page-template>
</template>

<script>
import { UButton } from 'universkin-shared';

import QuestionnairePageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnairePageTemplate';

export default {
  name: 'WeHaveGotYourPhotoTemplate',
  components: { QuestionnairePageTemplate, UButton },
  props: {
    doctorInfo: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    patientPhoto: {
      type: String,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.we-have-got-photo-page-content {
  max-width: 100%;

  &__main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 490px;
    max-width: 100%;
    padding-top: 85px;
  }

  &__text {
    margin: 16px 0 20px 0;
    text-align: center;
    color: var(--u-color-grey-600);

    &--link {
      color: var(--u-color-info);
      cursor: pointer;
    }
  }
}

.main-content {
  &__image {
    position: absolute;
    top: 0;
    width: 276px;
    height: 276px;
    border-radius: 10px;
    object-fit: cover;
  }

  &__action-buttons-wrapper {
    display: flex;
    justify-content: center;
    width: 328px;
    min-height: 348px;
    height: 100%;
    background-color: var(--u-color-grey-50);
    border-radius: 10px;
  }
}

.action-buttons {
  position: absolute;
  bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    min-width: 199px;
    margin-bottom: 8px;
  }
}

@media (min-width: $desktop-start) {
  .we-have-got-photo-page-content {
    &__text {
      margin: 8px 0 28px 0;
    }
  }

  .main-content {
    &__action-buttons-wrapper {
      width: 670px;
      height: 348px;
    }
  }
}
</style>
