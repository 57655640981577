<template>
  <modal
    name="photo-instructions-modal"
    class="questionnaire-modal-background pin-to-top-mobile-modal"
    :classes="['questionnaire-modal', 'no-mobile-padding-modal']"
    transition="fade"
    adaptive
    width="700px"
    :click-to-close="false"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="photo-instructions-modal-content">
      <photo-instructions>
        <u-button
          slot="action-button"
          class="photo-instructions__button q-mt17"
          kind="secondary"
          size="small"
          @click="onConfirm"
          >{{ $t('action.close') }}</u-button
        >
      </photo-instructions>
    </div>
  </modal>
</template>

<script>
import { UButton } from 'universkin-shared';

import PhotoInstructions from '@/modules/questionnaire/pages/lets-make-selfie/PhotoInstructions.vue';

export default {
  name: 'PhotoInstructionsModal',
  components: { UButton, PhotoInstructions },
  data() {
    return {
      onConfirm: null
    };
  },
  methods: {
    beforeOpen({ params: { onClose } }) {
      this.onConfirm = onClose;
    }
  }
};
</script>
